<template>
    <div class="aboutContainer">
        <div class="container">
            <h1>品牌故事</h1>

            <p>
                1990年代，生活品質與經濟飛速成長的時代，因為母親時常購買冰箱等大型傢俱想提高生活質量，多了很多需要汰換的大型傢俱，導致孩提時代的創辦人林御仁先生第一次體會到了大型物件汰換的不方便性所造成的困擾。
                創辦人長大成人後進入了台積電工作，在工作中吸取很多科技知識，也在一次偶然的機會上再次了解到大型物件物流的困難及不方便，便在2020年毅然決然的離開工作已久的台積電，下定決心打造出更適合消費者的大型貨運環境。
                在2021的今天，科技日新月異，唯有貨運及物流產業還停滯在20年前的困境，bigbox想要創造更便利的服務，推出了共享物流的模式，抱持著為了消費者著想的奉獻精神，帶給所有消費者更便利且快速的服務。
            </p>

            <h2>品牌價值</h2>

            <p>
                Bigbox將科技、托運及搬家三種不同的產業模式合而為一，打造全新的貨運時代，且利用共享物流模式，提供全民性價比極高的一種寄貨服務。
                以社會公共事業為致力目標，全心為消費者建立出更簡便的生活。
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Story"
    }
</script>